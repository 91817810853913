import Cookies from 'js-cookie'

import { DEFAULT_HEIGHT_IN_CM, DEFAULT_WEIGHT_IN_KG } from 'shared/constants'
import {
  HeightUnit,
  HeightValue,
  WeightUnit,
  WeightValue,
} from 'shared/providers/ClientDataProvider'
import { ONBOARDING_FINISHED_COOKIE_NAME } from 'shared/providers/FlowManagerProvider/constants'
import { roundTo } from 'shared/utils/numbers'
import { convertHeight, convertWeight } from 'shared/utils/unit-converters'

export const getOnboardingExpirationDate = () => {
  const cookieValue = Cookies.get(ONBOARDING_FINISHED_COOKIE_NAME)

  if (cookieValue) {
    return new Date(cookieValue)
  }

  return undefined
}

type UnitValue = {
  currentWeight: WeightValue
  height: HeightValue
}

export const getHeightValue = (height: UnitValue['height']) => {
  if (height.value === '') {
    return DEFAULT_HEIGHT_IN_CM
  }

  if (height.unit === HeightUnit.IN) {
    return convertHeight({ value: height.value, fromUnit: height.unit, toUnit: HeightUnit.CM })
  }

  return Number(height.value)
}

export const getWeightValue = (weight: UnitValue['currentWeight']) => {
  if (weight.value === '') {
    return DEFAULT_WEIGHT_IN_KG
  }

  if (weight.unit === WeightUnit.LB) {
    return convertWeight({ value: weight.value, fromUnit: weight.unit, toUnit: WeightUnit.KG })
  }

  return Number(weight.value)
}

export const calculateBMI = (
  inputHeight: UnitValue['height'],
  inputWeight: UnitValue['currentWeight']
) => {
  const height = getHeightValue(inputHeight)
  const weight = getWeightValue(inputWeight)

  const bmi = weight / (height / 100) ** 2

  return roundTo(bmi, 1)
}
